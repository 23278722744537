import { Link } from "react-router-dom";

import SupportButton from "./SupportButton";
import { Divider } from "@mui/material";
const Footer = () => {
  return (
    <footer
      id="footer"
      className="text-center pt-2"
      style={{
        backgroundColor: "#f6f8fa",
        borderTop: "1px solid rgba(0, 0, 0, 0.125)",
      }}
      role="contentinfo"
    >
      <span className="text-uppercase" style={{ fontSize: "12px" }}>
        &copy;{new Date().getFullYear()}{" "}
        <a
          href="https://mifotra.gov.rw/"
          target="_blank"
          rel="noreferrer"
          className="text-dark"
        >
          Ministry of Public Service and Labour (MIFOTRA)
        </a>
      </span>
      <div style={{ fontSize: "10px" }}>
        <a
          className="my-sm-0 text-uppercase text-blue-100"
          rel="noreferrer"
          href="http://recruitment.mifotra.gov.rw/"
          target="_blank"
        >
          E-Recruitment
        </a>{" "}
        <span aria-hidden="true">|</span>{" "}
        <a
          className="my-sm-0 text-uppercase text-blue-100"
          rel="noreferrer"
          href="https://ippis.rw/"
          target="_blank"
        >
          IPPIS
        </a>{" "}
        <span aria-hidden="true">|</span>{" "}
        <Link className="my-sm-0 text-uppercase text-blue-100" to="#">
          About IPPIS
        </Link>
      </div>
      <SupportButton
        content={
          <>
            <p className="text-left">
              We're glad you're here! <br />
              We're ready to assist you with whatever you need.
              <Divider className="py-2" />
              <div className="mt-2">
                Please{" "}
                <a className="text-blue-100 btn-link" href={`tel:9997`}>
                  call us at: <strong>9997</strong>
                </a>{" "}
                or message us at:{" "}
                <a
                  className="text-blue-100 btn-link"
                  href={`https://web.whatsapp.com/send?phone=0785569363&text=How%20May%20I%20help%20You?`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>0785569363</strong> (via WhatsApp for recruitment
                  inquiries)
                </a>
              </div>
            </p>
          </>
        }
        title={"Welcome"}
      />
    </footer>
  );
};

export default Footer;
